import {convertKontentColorToHex} from '@utils/Helpers'
import { KenticoImage, KenticoChoice } from '@utils/KontentTypes';
import { ReviewCardSliderProps } from './CustomerReviewCardSlider';

interface CustomerReviewDataProps {
    title: {
        value: string;
    };
    subtitle: {
        value: string;
    };
    title2: {
        value: string;
    };
    backgroundColors: {
        value: KenticoChoice[];
    };
    cards: {
        linkedItems: Array<{ elements: CustomerReviewCardProps }>;
    };
    rating: {
        value: number;
    };
    totalreviews: {
        value: number;
    };
    hasGoogleReview: boolean;
}

export const mapToCustomerReviewData = (data: any | undefined, hasGoogleReview: boolean): ReviewCardSliderProps|undefined =>{
    
    if(data == undefined)
        return undefined;
    const {title, backgroundColors, cards, rating, totalreviews, title2, subtitle} = data;
    const cardData = cards.linkedItems.map((card: any) =>mapToCustomerReviewCard(card.elements));
    return{
        title: title.value,
        title_2: title2.value,
        subtitle: subtitle.value,        
        backgroundColor: convertKontentColorToHex(backgroundColors.value[0].codename),
        sliders: cardData,
        rating: rating.value,
        totalReviews: totalreviews.value,
        hasGoogleReview: hasGoogleReview
    }
}

interface CustomerReviewCardProps{
        customerName:{
            value: string
        }
        avatar:{
            value: KenticoImage[]
        }
        rating: {
            value: number
        }
        title:{
            value: string
        }
        description:{
            value: string
        }
        createDate: {
            value: string
        }
}

export const mapToCustomerReviewCard = (card: CustomerReviewCardProps) =>{
    const {customerName,avatar,rating,title,description, createDate} = card;

    return{
        avatarUrl: avatar.value && avatar.value.length > 0 ? avatar.value[0].url : '',
        username: customerName.value,
        rating: rating.value,
        title: title.value,
        create_date: createDate.value,
        content: description.value,
        hasGoogleReview: true
    }
}